import BaseService from "./BaseService";

class ConfigService extends BaseService {
    constructor() {
        super();
        this._config = null;
        this.url = `${process.env.REACT_APP_API_URL}/maviret/v1`;
    }

    load(){
        if(navigator && navigator.onLine) {
            return super.get(`${this.url}/config`).then(response => {
                localStorage.setItem('app_config', JSON.stringify(response));
                this._config = response;
                return response;
            });
        }
        else{
            return new Promise((resolve,reject)=> {
                this._config = JSON.parse(localStorage.getItem('app_config'));
                resolve(this._config);
            });
        }
    }

    //This is try and access the in memory variable first
    //if that fails it will try and go to local storage
    //if that fails it will call the load function to get it from the server if online or localstorage if offline
    get() {
        return new Promise((resolve,reject)=> {
            if(!this._config)
            {
                this._config = JSON.parse(localStorage.getItem('app_config'));

                if(!this._config)
                {
                    this.load().then(()=>{
                        resolve(this._config);
                    })
                }else{
                    resolve(this._config);
                }
            }
            else {
                resolve(this._config);
            }
        });
    }
}

export default ConfigService;