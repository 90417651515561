import React from 'react';

const RichText = ({children}) => {
  return(
    <div className="rich-text">
      {children}
    </div>
  );
};

export { RichText };
