import React, { Component } from 'react';
import { Button, Input, Checkbox, Link, RichText } from '../elements';
import { Overlay, Footer } from '../sections';
import { connect } from 'react-redux';
import ConfigService from "../../services/ConfigService";
import * as actions from "../../actions";
import withExternalLinkListener from "../link-listener";
import ReactGA from 'react-ga';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ahpra_number: '',
      confirm: false,
      submitted: false,
      aphra_valid: false,
      remember_me: false,
      config:{},
      overlay: false,
      overlay_config: {},
      home_config: {}
    };

    this.onChangeText = this.onChangeText.bind(this);
    this.onClickCheckbox = this.onClickCheckbox.bind(this);
    this.onClickRememberMe = this.onClickRememberMe.bind(this);
    this.onLoginClick = this.onLoginClick.bind(this);
    this.toggleOverlay = this.toggleOverlay.bind(this);

    this.configService = new ConfigService();
    ReactGA.pageview('/login/');
  }


  componentDidMount() {
    this.configService.get().then((config)=>{
      this.setState ({
        config: config.landing_page,
        overlay_config: config.popups.more_information_home,
        home_config: config.home_page
      });
    });

    const ahpra_code = window.localStorage.getItem('ahpra_code')
    const hcp_confirmed = window.localStorage.getItem('hcp_confirmed')

    if (ahpra_code && hcp_confirmed) {
      this.setState({ ahpra_number: ahpra_code, aphra_valid:/^([A-z]{3}\d\d\d\d\d\d\d\d\d\d|[A-z]{8})$/.test(ahpra_code), confirm: true, remember_me: true});
      ReactGA.event({
        category: 'User',
        action: 'Successful login (revisit)'
      })
      this.props.loginUser();
      this.props.history.push('/');
    }
  }

  onChangeText(e) {
    this.setState({ ahpra_number: e.target.value, aphra_valid:/^([A-z]{3}\d\d\d\d\d\d\d\d\d\d|[A-z]{8})$/.test(e.target.value)});
  }

  onClickCheckbox(e) {
    this.setState({ confirm: e.target.checked });
  }

  onClickRememberMe() {
    this.setState({ remember_me: !this.state.remember_me });
  }

  onLoginClick() {
    this.setState({submitted:true});
    let { confirm, aphra_valid, ahpra_number, remember_me } = this.state;
    if (confirm && aphra_valid) {
      // window.localStorage.setItem('ahpra_code', ahpra_number)
      // window.localStorage.setItem('hcp_confirmed', confirm)
      if (remember_me) {
        window.localStorage.setItem('ahpra_code', ahpra_number)
        window.localStorage.setItem('hcp_confirmed', confirm)
      } else {
        window.localStorage.removeItem('ahpra_code')
        window.localStorage.removeItem('hcp_confirmed')
      }
      ReactGA.event({
        category: 'User',
        action: 'Successful login'
      })
      this.props.loginUser();
      this.props.history.push('/');
    } else {
      ReactGA.event({
        category: 'User',
        action: 'Failed login'
      })
    }
  }

  toggleOverlay(open) {
    this.setState({ overlay: open });
  }

  render() {
    return(
      <div className='home-wrapper'>
        <div className="container">
          <div className="home">
            <div className="home-title" dangerouslySetInnerHTML={{__html:this.state.config.title}}>
            </div>
            <div className="home-text" dangerouslySetInnerHTML={{__html:this.state.config.content}}>
            </div>
            <div className="home-login">
              <Input placeholder={this.state.config.ahpra_watermark} value={this.state.ahpra_number} onChange={this.onChangeText} isValid={!this.state.submitted || this.state.aphra_valid} />
              <Checkbox label={this.state.config.confirmation} value={this.state.confirm} onClick={this.onClickCheckbox} isValid={!this.state.submitted || this.state.confirm} />
              <Checkbox label="Remember me" value={this.state.remember_me} onClick={this.onClickRememberMe} isValid={true} />
              <Button text={this.state.config.login_button} onClick={this.onLoginClick} />
              <Link url={this.state.config.practitioners_link} />
            </div>
            <div className="home-caption" dangerouslySetInnerHTML={{__html:this.state.config.outro}}>
            </div>
            <div className="start-caption">
              <div className="reference-link" onClick={() => this.props.toggleReferences(true)} dangerouslySetInnerHTML={{__html:this.state.home_config.references_and_abbreviations}}></div>
            </div>
          </div>
        </div>

        <Footer />
        <Overlay open={this.state.overlay} toggleOverlay={this.toggleOverlay}>
          <div className="overlay-body">
            <div className="overlay-title" dangerouslySetInnerHTML={{__html:this.state.overlay_config.title}}>
            </div>
            <div className="overlay-section">
              <RichText>
                <div dangerouslySetInnerHTML={{__html:this.state.overlay_config.content}}></div>
              </RichText>
            </div>
          </div>
        </Overlay>
      </div>
    );
  }
}

export default connect(null, actions)(withExternalLinkListener(Login));
