import React, { Component } from 'react';
import ConfigService from "../../services/ConfigService";
import withExternalLinkListener from "../link-listener";
import ReactGA from 'react-ga';

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      config:{}
    };

    this.configService = new ConfigService();
  }

  componentDidMount() {
    this.configService.get().then((config)=>{
      this.setState ({
        config: config.footer
      });
    });
  }

  render() {
    return(
      <div className="footer">
        <div className="footer-title">
          <div className="footer-logo">
            <img src={this.state.config.logo} alt="abbvie" />
          </div>
        </div>
        <div className="footer-links">
          {this.renderLinks()}
        </div>
        <div className="footer-caption" dangerouslySetInnerHTML={{__html:this.state.config.text}}>
        </div>
      </div>
    );
  }

  renderLinks() {
    if(this.state.config.nav_items) {
      return this.state.config.nav_items.map(item => {
        return(
          <a key={item.title} href={item.url} target="_blank" onClick={() => {
            ReactGA.event({
              category: 'Link click',
              action: item.url
            });
          }}>{item.title}</a>
        );
      })
    }
  }
}

export default withExternalLinkListener(Footer);
