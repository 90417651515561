import React, { Component } from 'react';
import { Button, Checkbox, RichText } from '../elements';
import {Overlay, FormFooter, Footer} from '../sections';
import * as actions from '../../actions';
import { connect } from 'react-redux';
import ConfigService from "../../services/ConfigService";
import withExternalLinkListener from "../link-listener";
import ReactGA from 'react-ga';

class Start extends Component {
  constructor(props) {
    super(props);

    this.state = {
      overlay: false,
      config: {},
      overlay_config: {},
      confirm: false,
      submitted: false
    };

    this.toggleOverlay = this.toggleOverlay.bind(this);
    this.onStart = this.onStart.bind(this);
    this.onClickCheckbox = this.onClickCheckbox.bind(this);

    this.configService = new ConfigService();
  }

  componentDidMount() {
    this.configService.get().then((config)=>{
      this.setState ({
        config: config.home_page,
        overlay_config: config.popups.more_information_home
      });
    });
  }

  onClickCheckbox(e) {
    this.setState({ confirm: e.target.checked });
  }
  onStart() {
    this.setState({ submitted: true });

    let { confirm } = this.state;
    if(confirm) {
      this.props.history.push('/form');
    }
  }

  toggleOverlay(open) {
    this.setState({ overlay: open });
  }

  render() {
    ReactGA.pageview('/start');
    return(
      <div className='start-wrapper'>

        <div className="container">
          <div className="start">
            <div className="start-title" dangerouslySetInnerHTML={{__html:this.state.config.title}}>
            </div>
            <div className="start-more-info">
              <Button text={this.state.config.more_information_button} alternate onClick={() => this.toggleOverlay(true)} />
            </div>
            <div className="start-check">
              <Checkbox label={this.state.config.pcr_assay} value={this.state.confirm} onClick={this.onClickCheckbox} isValid={!this.state.submitted || this.state.confirm} />
            </div>
            <div className="start-rich-text">
              <RichText>
                <div dangerouslySetInnerHTML={{__html:this.state.config.content}}>
                </div>
              </RichText>
            </div>
            <div className="start-caption">
              <div className="reference-link" onClick={() => this.props.toggleReferences(true)} dangerouslySetInnerHTML={{__html:this.state.config.references_and_abbreviations}}></div>
            </div>
          </div>
        </div>

        <FormFooter>
          <div className="start-footer">
            <Button text="Start" onClick={this.onStart}/>
          </div>
          <Footer />
        </FormFooter>

        <Overlay open={this.state.overlay} toggleOverlay={this.toggleOverlay}>
          <div className="overlay-body">
            <div className="overlay-title" dangerouslySetInnerHTML={{__html:this.state.overlay_config.title}}>
            </div>
            <div className="overlay-section">
              <RichText>
                <div dangerouslySetInnerHTML={{__html:this.state.overlay_config.content}}></div>
              </RichText>
            </div>
          </div>
        </Overlay>
      </div>
    );
  }
}

export default connect(null, actions)(withExternalLinkListener(Start));
