import React from 'react';

const Tile = ({children}) => {
  return(
    <div className="tile">
      {children}
    </div>
  );
};

export { Tile };
