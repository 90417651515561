import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import ConfigService from "../../services/ConfigService";
import withExternalLinkListener from "../link-listener";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      config:{}
    };

    this.configService = new ConfigService();
    this.menu_dropdown = null;
    this.content = null;
  }

  componentDidMount() {
    this.configService.get().then((config)=>{
      this.setState ({
        config: config.header
      });
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.open) {
      this.menu_dropdown.style.height = this.content.clientHeight + "px";
    } else {
      this.menu_dropdown.style.height = 0;
    }
  }

  render() {
    let classes = ['header'];
    if(this.props.open) classes.push('active');

    let icon = ['icon'];
    if(this.props.open) icon.push('active');

    return(
      <div className={classes.join(' ')}>
        <Link to={'/'}>
          <div className="header-logo">
            <img src={this.state.config.logo} alt="HCV Logo" />
          </div>
        </Link>
        <div className="header-menu">
          <div className="header-links" ref={r => this.menu_dropdown = r}>
            <div className="header-links-content" ref={r => this.content = r}>
              {this.renderLinks()}
            </div>
          </div>
          <div className="header-overlay" onClick={() => this.props.toggleMenu(false)} />
        </div>
        <div className="header-mobile-btn">
          <div className={icon.join(' ')} onClick={() => this.props.toggleMenu(!this.props.open)}>
            <div className="burger-1"></div>
            <div className="burger-2"></div>
            <div className="burger-3"></div>
            <div className="burger-4"></div>
          </div>
        </div>
      </div>
    );
  }

  renderLinks() {
    if(this.state.config.nav_items) {
      let total = this.state.config.nav_items.length;
      return this.state.config.nav_items.map((item, i) => {
        let transition_delay = 0;
        if(this.props.open) {
          transition_delay = (i + 2) * 150;
        } else {
          transition_delay = (total - (i + 1)) * 150;
        }
        let target = "";
        if(item.new_tab) target = "_blank";
        if(item.url.startsWith('/'))
        {
          return(
              <Link style={{ transitionDelay: `${transition_delay}ms` }} key={item.title} to={item.url} onClick={() => this.props.toggleMenu(false)} target={target}>{item.title}</Link>
          );
        }
        else{
          return(
              <a style={{ transitionDelay: `${transition_delay}ms` }} key={item.title} href={item.url} onClick={() => this.props.toggleMenu(false)} target={target}>{item.title}</a>
          );
        }

      })
    }
  }
}

function mapStateToProps(state) {
  return { open: state.menu };
}

export default connect(mapStateToProps, actions)(withExternalLinkListener(Header));
