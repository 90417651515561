import React, { Component } from 'react';
import { Tile } from '../elements';
import { Button } from '../elements';
import withExternalLinkListener from "../link-listener";
import ReactGA from 'react-ga';

class WeekTile extends Component {
  render() {
    const { title, weeks, brand, extra, btnLeft, onClickLeft, btnRight, rightButtonUrl } = this.props;
    return(
      <Tile>
        <div className="tile-title">{title}</div>
        <div className="tile-title">{brand}</div>
        <div className="tile-weeks">{weeks}</div>
        <div className="tile-extra">{extra}</div>
        <div className="tile-buttons">
          <div className="row">
            <div className="col-6"><Button text={btnLeft} onClick={onClickLeft}/></div>
    <div className="col-6"><a className="btn blue" href={rightButtonUrl} target="_blank" onClick={() => {
      ReactGA.event({
        category: 'Link click',
        action: rightButtonUrl
      });
    }}>{btnRight}</a></div>
          </div>
        </div>
      </Tile>
    );
  }
}

export default withExternalLinkListener(WeekTile);
