import React, { Component } from 'react';
import {WeekTile, IconTile, Overlay, TreatmentTiles, FormFooter, AfterDisclaimerTile} from '../../sections';
import {Button, RichText} from "../../elements";
import * as actions from '../../../actions';
import { connect } from 'react-redux';
import withExternalLinkListener from "../../link-listener";
import ReactGA from 'react-ga';

let config_overlay_default = {
  blue_boxes: [],
  grey_boxes: []
};

class StepSix extends Component {
  constructor(props) {
    super(props);

    this.state = {
      overlay: false,
      config: {},
      selected: 0,
      config_overlay: config_overlay_default
    };

    this.toggleOverlay = this.toggleOverlay.bind(this);
    ReactGA.pageview('/step-6/' + this.props.location.state.selected);
  }

  componentDidMount() {
    this.getConfig();
  }

  toggleOverlay(open, type) {
    const { config } = this.props;
    let config_overlay = config_overlay_default;
    if(open) {
      
      ReactGA.event({
        category: 'User',
        action: 'Open monitoring overlay: ' + type
      });

      if(type === 1) config_overlay = config.popups.monitoring_for_maviret;
      else if(type === 2) config_overlay = config.popups.monitoring_for_harvoni;
      else if(type === 3) config_overlay = config.popups.monitoring_for_epclusa;
      else if(type === 4) config_overlay = config.popups.monitoring_for_zepatier;
      else return;

      if(!config_overlay) return;
      this.setState({ overlay: open, config_overlay });
    } else {
      this.setState({ overlay: open }, () => {
        setTimeout(() => {
          this.setState({config_overlay})
        }, 300);
      });
    }
  }

  getConfig() {
    let { config } = this.props;
    this.setState({ config: config.result_1, selected: 1 });
  }

  startNewPatient = () => {
    ReactGA.event({
      category: 'User',
      action: 'Start New Patient (from step-6)'
    })
    this.props.mainHistory.push('/')
  }

  render() {
    const { config, config_overlay } = this.state;
    return(
      <div className="step">
        <div className="container">
          <div className="step-body">
            <div className="step-title" dangerouslySetInnerHTML={{__html:config.title}}>
            </div>
            <div className="step-main">
              <div className="step-info" dangerouslySetInnerHTML={{__html:config.content}}>
              </div>
              {this.renderTiles()}
              <div className="step-caption">
                <div className="reference-link" onClick={() => this.props.toggleReferences(true)} dangerouslySetInnerHTML={{__html:config.references_and_abbreviations}}></div>
              </div>
              <div className="step-tiles-caption" dangerouslySetInnerHTML={{__html:config.trademarks}}>
              </div>
            </div>
          </div>

        </div>
        <FormFooter>
          <div className="step-footer">
            <Button text={this.state.config.start_again_button} onClick={this.startNewPatient} />
          </div>
        </FormFooter>
        <Overlay open={this.state.overlay} toggleOverlay={() => this.toggleOverlay(false)}>
          <div className="overlay-body">
            <div className="overlay-title" dangerouslySetInnerHTML={{__html:config_overlay.title}}>
            </div>
            <div className="overlay-section">
              <RichText>
                <div className="overlay-text-center" dangerouslySetInnerHTML={{__html:config_overlay.intro}}></div>
              </RichText>
              <div className="overlay-icon-tiles">
                {this.renderOverlayGreyBoxes(config_overlay)}
              </div>
              <div className="overlay-second-title" dangerouslySetInnerHTML={{__html:config_overlay.monitoring_title}}>
              </div>
              <TreatmentTiles title={config_overlay.treatment_box_title} content={config_overlay.treatment_box_content} blue_boxes={config_overlay.blue_boxes} />
              <div className="overlay-cure-disclaimer" dangerouslySetInnerHTML={{__html:config_overlay.cure_disclaimer}}>
              </div>
              <AfterDisclaimerTile after_disclaimer={config_overlay.after_disclaimer} />
            </div>
          </div>
        </Overlay>
      </div>
    );
  }

  renderTiles() {
    const { config, selected } = this.state;
    const { grey_box_1: box1, grey_box_2: box2, grey_box_3: box3, grey_box_4: box4 } = config;
    let boxes1 = [];
    let boxes2 = [];
    switch(selected) {
      case 1 :
        boxes1.push(
          <div key="grey_box_1" className="col-md-6">
            <WeekTile title={box1.line_1} brand={box1.line_2} weeks={box1.line_3} extra={box1.line_4} btnLeft={box1.button_1} btnRight={box1.button_2}
                      onClickLeft={() => this.toggleOverlay(true, 1)} rightButtonUrl={box1.button_2_url}
            />
          </div>
        );
        boxes1.push(
          <div key="grey_box_3" className="col-md-6">
            <WeekTile title={box3.line_1} brand={box3.line_2} weeks={box3.line_3} extra={box3.line_4} btnLeft={box3.button_1} btnRight={box3.button_2}
                      onClickLeft={() => this.toggleOverlay(true, 3)} rightButtonUrl={box3.button_2_url}
            />
          </div>
        );
        break;
      case 2 :
        boxes1.push(
          <div key="grey_box_1" className="col-md-6">
            <WeekTile title={box1.line_1} brand={box1.line_2} weeks={box1.line_3} extra={box1.line_4} btnLeft={box1.button_1} btnRight={box1.button_2}
                      onClickLeft={() => this.toggleOverlay(true, 1)} rightButtonUrl={box1.button_2_url}
            />
          </div>
        );
        boxes1.push(
          <div key="grey_box_3" className="col-md-6">
            <WeekTile title={box2.line_1} brand={box2.line_2} weeks={box2.line_3} extra={box2.line_4} btnLeft={box2.button_1} btnRight={box2.button_2}
                      onClickLeft={() => this.toggleOverlay(true, 3)} rightButtonUrl={box2.button_2_url}
            />
          </div>
        );
        boxes2.push(
          <div key="grey_box_4" className="col-md-6">
            <WeekTile title={box3.line_1} brand={box3.line_2} weeks={box3.line_3} extra={box3.line_4} btnLeft={box3.button_1} btnRight={box3.button_2}
                      onClickLeft={() => this.toggleOverlay(true, 4)} rightButtonUrl={box3.button_2_url}
            />
          </div>
        );
        break;
      case 3 :
        boxes1.push(
          <div key="grey_box_1" className="col-md-6">
            <WeekTile title={box1.line_1} brand={box1.line_2} weeks={box1.line_3} extra={box1.line_4} btnLeft={box1.button_1} btnRight={box1.button_2}
                      onClickLeft={() => this.toggleOverlay(true, 1)} rightButtonUrl={box1.button_2_url}
            />
          </div>
        );
        boxes1.push(
          <div key="grey_box_3" className="col-md-6">
            <WeekTile title={box2.line_1} brand={box2.line_2} weeks={box2.line_3} extra={box2.line_4} btnLeft={box2.button_1} btnRight={box2.button_2}
                      onClickLeft={() => this.toggleOverlay(true, 3)} rightButtonUrl={box2.button_2_url}
            />
          </div>
        );
        break;
      default :
        return null;
    }

    return(
      <div className="step-tiles">
        <div className="step-tiles-desktop">
          <div className="row">
            {boxes1}
          </div>
          <div className="row">
            {boxes2}
          </div>
        </div>
        <div className="step-tiles-mobile">
          <div className="row">
            {[...boxes1, ...boxes2]}
          </div>
        </div>
      </div>
    );
  }

  renderOverlayGreyBoxes(config_overlay) {
    return config_overlay.grey_boxes.map((box, i) => {
      return(
        <IconTile key={`grey_box_${i}`}
          line_1={box.line_1}
          line_2={box.line_2}
          number={box.circle_number}
          unit={box.circle_unit}
          line_3={box.line_3}
          line_4={box.line_4}
        />
      );
    })
  }
}

export default connect(null, actions)(withExternalLinkListener(StepSix));
