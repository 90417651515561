import React, { Component } from 'react';
const tick = require('../../assets/images/tick.svg');
const cross = require('../../assets/images/cross.svg');

class CollapsibleTile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };

    this.onOpen = this.onOpen.bind(this);
    this.section = null;
    this.content = null;
  }

  onOpen(open) {
    if(open) {
      this.section.style.height = this.content.clientHeight + "px";
    } else {
      this.section.style.height = 0;
    }
    this.setState({ open });
  }

  render() {
    let { config, alternate, withRows } = this.props;
    let classSet = ['collapsible-tile'];
    if(this.state.open) {
      classSet.push('open');
    }
    if(alternate) {
      return(
        <div className="collapsible-tile alternate">
          {this.renderAlternate(config, withRows)}
        </div>
      );
    } else {
      return(
        <div className={classSet.join(' ')}>
          <div className="main">
            
            <div className="collapsible-tile-main">
              {config.line_1 ?
                <div className="collapsible-tile-row">
                  <img src={config.line_1_icon === 'tick' ? tick : cross} alt={config.line_1_icon} />
                  <div className="ct-label">{config.line_1}</div>
                </div>
                : null
              }
              {config.line_2 ?
                <div className="collapsible-tile-row">
                  <img src={config.line_2_icon === 'tick' ? tick : cross} alt={config.line_2_icon} />
                  <div className="ct-label">{config.line_2}</div>
                  <div className="ct-extra">{config.line_2_sub}</div>
                </div>
                : null
              }
            </div>
          </div>
          <div className="secondary">
            <div className="collapsible-tile-section">
              <div className="collapsible-tile-section-content">
                {config.reveal}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  renderAlternate(config, withRows) {
    if(withRows) {
      return(
        <div className="main">
          <div className="collapsible-tile-close" onClick={() => this.onOpen(!this.state.open)}>
            <img src={require('../../assets/images/dropdown.svg')} alt="Dropdown" />
          </div>
          <div className="collapsible-tile-main">
            {config.line_1 ?
              <div className="collapsible-tile-row">
                <img src={config.line_1_icon === 'tick' ? tick : cross} alt={config.line_1_icon} />
                <div className="ct-label">{config.line_1}</div>
              </div>
              : null
            }
            {config.line_2 ?
              <div className="collapsible-tile-row">
                <img src={config.line_2_icon === 'tick' ? tick : cross} alt={config.line_2_icon} />
                <div className="ct-label">{config.line_2}</div>
                <div className="ct-extra">{config.line_2_sub}</div>
              </div>
              : null
            }
          </div>
        </div>
      );
    } else {
      return(
        <div className="secondary">
          <div className="collapsible-tile-section" ref={r => this.section = r}>
            <div className="collapsible-tile-section-content" ref={r => this.content = r}>
              {config.reveal}
            </div>
          </div>
        </div>
      );
    }
  }
}

export { CollapsibleTile };
