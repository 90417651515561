import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';
import { RichText } from './elements';
import { Overlay } from './sections';
import ConfigService from "../services/ConfigService";
import withExternalLinkListener from "./link-listener";
import ReactGA from 'react-ga'

class ReferenceOverlay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      config:{}
    };

    this.configService = new ConfigService();
  }

  componentDidMount() {
    this.configService.get().then((config)=>{
      this.setState ({
        config: config.popups.references_and_abbreviations
      });
    });
  }

  render() {
    return(
      <Overlay open={this.props.open} toggleOverlay={this.props.toggleReferences}>
        <div className="overlay-body">
          <div className="overlay-title" dangerouslySetInnerHTML={{__html:this.state.config.title}}>
          </div>
          <div className="overlay-section">
            <RichText>
              <div dangerouslySetInnerHTML={{__html:this.state.config.content}}></div>
            </RichText>
          </div>
        </div>
      </Overlay>
    );
  }
}

function mapStateToProps(state) {
  return { open: state.overlay };
}

export default connect(mapStateToProps, actions)(withExternalLinkListener(ReferenceOverlay));
