import React, { Component } from 'react';
import { Tile, RichText, CollapsibleTile } from '../elements';
import withExternalLinkListener from "../link-listener";

class AfterDisclaimerTile extends Component {
  render() {
    const { after_disclaimer } = this.props;
    return(
        <div className="treatment">
        <div className="treatment-mobile">
          <div className="treatment-tile line-below">
          <Tile>
            <div dangerouslySetInnerHTML={{__html:after_disclaimer}}></div>
          </Tile>
      </div>
        </div>
        <div className="treatment-tile-wrapper">
          <div className="row">
            <div className="col-4"></div>
            <div className="col-4 treatment-desktop-tile">
              <div className="treatment-tile line-below">
                <Tile>
                  <div dangerouslySetInnerHTML={{__html:after_disclaimer}}></div>
                </Tile>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withExternalLinkListener(AfterDisclaimerTile);
