import React, { Component } from 'react';
import {FormFooter, Overlay} from '../../sections';
import {Button, RichText} from '../../elements';
import * as actions from '../../../actions';
import { connect } from 'react-redux';
import withExternalLinkListener from "../../link-listener";
import ReactGA from 'react-ga';

class StepFive extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nextDisabled: false,
      backDisabled: false,
      selected: 'GT-1',
      overlay: false
    };

    this.toggleOverlay = this.toggleOverlay.bind(this);
    ReactGA.pageview('/step-5/');
  }

  componentDidMount() { }

  toggleOverlay(open) {
    this.setState({ overlay: open });
  }

  render() {
    const { config, popups } = this.props;
    const { more_information_genotype: popup } = popups;
    return(
      <div className="step">
        <div className="container">
          <div className="step-body">
            <div className="step-title" dangerouslySetInnerHTML={{__html:config.title}}>
            </div>
            <div className="step-main">
              <div className="step-content">
              <div className="step-info" dangerouslySetInnerHTML={{__html:config.content}}></div>
              <div className="step-caption">
                <div className="reference-link" onClick={() => this.props.toggleReferences(true)} dangerouslySetInnerHTML={{__html:config.references_and_abbreviations}}></div>
              </div>
              </div>
            </div>
          </div>
        </div>

        <FormFooter
          nextDisabled={this.state.nextDisabled}
          backDisabled={this.state.backDisabled}
          nextClick={() => {
            this.setState({ nextDisabled: false, selected: 'GT1' })
            this.props.history.push({pathname: '/6', state: {selected: this.state.selected}})
            }
          }
          backClick={() => this.props.history.goBack()}
        />

        <Overlay open={this.state.overlay} toggleOverlay={this.toggleOverlay}>
          <div className="overlay-body">
            <div className="overlay-title" dangerouslySetInnerHTML={{__html:popup.title}}>
            </div>
            <div className="overlay-section">
              <RichText>
                <div dangerouslySetInnerHTML={{__html:popup.content}}></div>
              </RichText>
            </div>
          </div>
        </Overlay>
      </div>
    );
  }
}

export default connect(null, actions)(withExternalLinkListener(StepFive));
