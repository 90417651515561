import React, { Component } from 'react';
import withExternalLinkListener from "../link-listener";

class ProgressBar extends Component {
  render() {
    return(
      <div className="progress-bar-wrapper">
        <ol className="progress-bar">
          {this.renderSteps()}
        </ol>
        <div className="progress-bar-underlay" />
      </div>
    );
  }

  renderSteps() {
    let { steps, currentStep } = this.props;
    let points = [];
    for(let i = 0; i < steps; i++) {
      let classSet = ['progress-bar-step'];
      if(i < currentStep - 1) classSet.push('is-complete');
      if(i === currentStep - 1) classSet.push('is-current');
      if(i !== 0 && i !== 5) classSet.push('is-middle'); // Required for IE positioning 
      if(i == 5) classSet.push('is-last');
      points.push(
        <li key={`step${i}`} className={classSet.join(' ')}>
          <svg className="progress-bar-icon-underlay" />
          <svg className="progress-bar-icon-placeholder" />
          <svg className="progress-bar-icon" />
        </li>
      )
    }
    return points;
  }
}

export default withExternalLinkListener(ProgressBar);
          