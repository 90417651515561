import React, { Component } from 'react';
import ReactGA from 'react-ga';

class Overlay extends Component {
  render() {
    let { open, toggleOverlay } = this.props;
    let classSet = ['overlay'];
    if(open) {
      classSet.push('open');

      ReactGA.event({
        category: 'User',
        action: 'Open Overlay - ' + this.props.children
                                     .props.children[0]
                                     .props.dangerouslySetInnerHTML
                                     .__html
      });
    }

    return(
      <div className={classSet.join(' ')}>
        <div className="overlay-header">
          <div className="overlay-close" onClick={() => toggleOverlay(false)}>
            <img src={require('../../assets/images/close-button.svg')} alt="Close" />
          </div>
        </div>
        <div className="overlay-content-wrapper">
          <div className="overlay-content">
            <div className="container">
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Overlay;
