import React, { Component } from 'react';
import * as ReactDOM from "react-dom";
import {Button, RichText} from "./elements";
import {Overlay} from "./sections";
import ConfigService from "../services/ConfigService";



export default function withExternalLinkListener(WrappedComponent) {
    return class extends Component {
        constructor(props) {
            super(props);

            this.state = {
                overlay: false,
                newTab: false,
                url: null,
                config:{}
            };

            this.toggleOverlay = this.toggleOverlay.bind(this);
            this.doStuff = this.doStuff.bind(this);


            this.configService = new ConfigService();
        }


        toggleOverlay(open) {
            this.setState({ overlay: open });
        }


        doStuff(e){
            e.preventDefault();
            this.setState({newTab:true,url:e.target.href})
            this.toggleOverlay(true);
        }

        componentDidMount() {

            let comp = new RegExp(window.location.host);
            let abbvie = new RegExp("abbvie.com.au");

            setTimeout(()=>{
                const node = ReactDOM.findDOMNode(this);

                let anchorElems = node.getElementsByTagName('a');
                let anchors = Array.prototype.slice.call(anchorElems);

                anchors.forEach((anchor)=>{
                    if(!abbvie.test(anchor.href) && !comp.test(anchor.href)){
                        anchor.onclick = this.doStuff;
                    }
                });
            });

            this.configService.get().then((config)=>{
                this.setState ({
                    config: config.popups.external_link
                });
            });

        }

        render() {
            // ... and renders the wrapped component with the fresh data!
            // Notice that we pass through any additional props
            return (
                <div>
                    <WrappedComponent {...this.props} />
                    <Overlay open={this.state.overlay} toggleOverlay={this.toggleOverlay}>
                        <div className="overlay-body">
                            <div className="overlay-title" dangerouslySetInnerHTML={{__html:this.state.config.title}}>
                            </div>
                            <div className="overlay-section">
                                <RichText>
                                    <div dangerouslySetInnerHTML={{__html:this.state.config.content}}></div>
                                </RichText>
                                <Button style={{marginTop:'50px'}} text={this.state.config.proceed_button} onClick={() => {
                                        if(this.state.newTab){
                                            window.open(this.state.url,'_blank');
                                        }
                                        else{
                                            window.open(this.state.url);
                                        }
                                        this.setState({overlay:false});
                                    }
                                } active={this.state.selected === 'Yes'} />
                            </div>
                        </div>
                    </Overlay>
                </div>
            );
        }
    };
}