import React from 'react';

const Checkbox = ({ label, value, onClick, isValid}) => {
  return(
    <label className="checkbox">{label}
      <input type="checkbox" checked={value} onChange={onClick} />
      <span className={`checkmark${isValid?'':' invalid'}`} />
    </label>
  );
};

export { Checkbox };
