import React, { Component } from 'react';
import {Input} from "./input";
import ConfigService from "../../services/ConfigService";

class Calculator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            config:{},
            calculator:{
                input_1:'',
                input_2:'',
                input_3:'',
                result:''
            }
        };

        this.configService = new ConfigService();

        this.calculateResult = this.calculateResult.bind(this);

    }

    componentWillMount() {
        this.configService.get().then(config=>{
            this.setState({config:config.popups.apri_calculator.calculator});
        });
    }

    calculateResult(e){

        this.setState({calculator:
                {
            ...this.state.calculator,
            [e.target.name]:e.target.value
        }},()=>{
            let {input_1,input_2,input_3 } = this.state.calculator;

            if(input_1 && input_2 && input_3){
                this.setState({
                    calculator:{
                        ...this.state.calculator,
                        result: Math.round((input_1 / input_2 / input_3 * 100)*1000)/1000 //1000 is to round to 3 decimal places
                    }
                });
            }
        });
    }


    render() {
        return (
        <div className="calculator">
            <div className="calculator-field-group calculator-top">
                <label>{this.state.config.text_1}</label>
                <Input name="input_1" value={this.state.calculator.input_1} type="number" onChange={this.calculateResult} isValid={true}/>
            </div>
            <div className="calculator-divider">
                <hr/>
            </div>
            <div className="calculator-field-group calculator-top">
                <label>{this.state.config.text_2}</label>
                <Input name="input_2" value={this.state.calculator.input_2} type="number" onChange={this.calculateResult} isValid={true}/>
            </div>
            <div className="calculator-APRI">
                <table>
                    <tbody>
                        <tr>
                            <td>APRI=</td>
                            <td className="calculator-APRI-line"><hr/></td>
                            <td>X100</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="calculator-field-group">
                <label>{this.state.config.text_3}</label>
                <Input name="input_3" value={this.state.calculator.input_3} type="number" onChange={this.calculateResult} isValid={true}/>
            </div>
            <div className="calculator-field-group calculator-result">
                <label>{this.state.config.result_text}</label>
                <Input value={this.state.calculator.result} readOnly={true} isValid={true}/>
            </div>
            <div className="calculator-disclaimer">{this.state.config.disclaimer}</div>
        </div>)
    }

}

export { Calculator };
