import React, { Component } from 'react';
import { Tile, RichText, CollapsibleTile } from '../elements';
import withExternalLinkListener from "../link-listener";

class TreatmentTiles extends Component {
  render() {
    const { title, content, blue_boxes } = this.props;
    return(
      <div className="treatment">
        <div className="treatment-mobile">
          <div className="treatment-tile line-below">
            <Tile>
              <div dangerouslySetInnerHTML={{__html:title}}></div>
              <RichText>
                <div dangerouslySetInnerHTML={{__html:content}}></div>
              </RichText>
            </Tile>
          </div>
          {blue_boxes.map((box, i) => {
            return(
              <div key={`blue_box_${i}`} className="treatment-tile line-below">
                <CollapsibleTile config={box} />
              </div>
            );
          })}
        </div>
        <div className="treatment-tile-wrapper">
          <div className="row">
            <div className="col-4"></div>
            <div className="col-4 treatment-desktop-tile">
              <div className="treatment-tile line-below">
                <Tile>
                  <div dangerouslySetInnerHTML={{__html:title}}></div>
                  <RichText>
                    <div dangerouslySetInnerHTML={{__html:content}}></div>
                  </RichText>
                </Tile>
              </div>
            </div>
            <div className="col-4"></div>
          </div>
          <div className="treatment-desktop">
            <div className="row">
              {blue_boxes.map((box, i) => {
                return(
                  <div key={`blue_box_${i}`} className="col-4 line-left">
                    <div className="treatment-tile">
                      <CollapsibleTile config={box} alternate withRows />
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="row">
              {blue_boxes.map((box, i) => {
                return(
                  <div key={`blue_box_${i}`} className="col-4">
                    <div className="treatment-tile">
                      <CollapsibleTile config={box} alternate />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="treatment-mobile">
            <div className="row">
              {blue_boxes.map((box, i) => {
                return(
                  <div key={`blue_box_${i}`} className="col-4">
                    <div className="treatment-tile">
                      <CollapsibleTile config={box} />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withExternalLinkListener(TreatmentTiles);
