import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Login, About, Form, Start, Result } from './pages';
import RouteWithProps  from './route-with-props';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import ConfigService from "../services/ConfigService";
import require_auth from "./auth/require_auth";

class Router extends Component {
  constructor(props) {
    super(props);

    this.state = {
      popups: {}
    };

    this.configService = new ConfigService();
  }

  
  componentDidMount() {

    this.configService.get().then((config)=>{
      this.setState ({
        popups: config.popups
      });
    });
  }

  render() {
    return(
      <Route
        render={({ location }) => (
            <div style={{paddingTop:'55px'}}>
                  <TransitionGroup className="outer-transition-group">
                    <CSSTransition
                      key={location.key}
                      classNames="fade-outer"
                      timeout={300}
                    >
                      <Switch location={location}>
                        <Route path="/login" exact component={Login} />
                        <Route path="/about" exact component={About} />
                        <Route path="/" exact component={require_auth(RouteWithProps(Start, {toggleReferences: this.props.toggleReferences}))} />
                        <Route path="/form" exact component={require_auth(RouteWithProps(Form, {toggleReferences: this.props.toggleReferences}))} />
                        <Route path="/result" exact component={require_auth(RouteWithProps(Result, {popups: this.state.popups, popupOpen: false}))} />
                        <Route path="/result_patient_egfr" exact component={require_auth(RouteWithProps(Result, {popups: this.state.popups, popupOpen: true}))} />
                        <Route render={() => <div className="page-not-found" style={{padding: 20}}>Page not found.</div>} />
                      </Switch>
                    </CSSTransition>
                  </TransitionGroup>
            </div>
        )}
      />
    );
  }
}

export default Router;
