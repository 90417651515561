import React, { Component } from 'react';
import {Footer, FormFooter, Overlay} from '../sections';
import { Button, TabTile, RichText } from '../elements';
import { connect } from 'react-redux';
import ConfigService from "../../services/ConfigService";
import withExternalLinkListener from "../link-listener";
import ReactGA from 'react-ga';

class Result extends Component {
  constructor(props) {
    super(props);

    this.state = {
      config: {},
      overlay: this.props.popupOpen
    };

    this.toggleOverlay = this.toggleOverlay.bind(this);
    this.configService = new ConfigService();
    ReactGA.pageview('/referral-recommended/');
  }

  toggleOverlay(open) {
    this.setState({ overlay: open });
  }

  startNewPatient = () => {
    this.props.history.push('/')
    ReactGA.event({
      category: 'User',
      action: 'Start New Patient (from referral-recommended)'
    })
  }

  componentDidMount() {
    this.configService.get().then((config)=>{
      this.setState ({
        config: config.referral_recommended_page
      });
    });
  }

  render() {
    const { config, popups } = this.props;
    const { more_information_patient_egfr: popup } = popups;

    return(
      <div className='result'>
        <div className="container">
          <div className="result-title" dangerouslySetInnerHTML={{__html:this.state.config.title}}>
          </div>
          <TabTile title={this.state.config.box_title} content={this.state.config.box_content} />
        </div>
        <FormFooter>
          <div className="result-footer">
            <Button text={this.state.config.button} onClick={this.startNewPatient} />
          </div>
          <Footer/>
        </FormFooter>
        <Overlay open={this.state.overlay} toggleOverlay={this.toggleOverlay}>
          <div className="overlay-body">
            <div className="overlay-title" dangerouslySetInnerHTML={{__html:popup.title}}>
            </div>
            <div className="overlay-section">
              <RichText>
                <div dangerouslySetInnerHTML={{__html:popup.content}}></div>
              </RichText>
            </div>
          </div>
        </Overlay>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { menu_open: state.menu }
}

export default connect(mapStateToProps)(withExternalLinkListener(Result));
