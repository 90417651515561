import React, { Component } from 'react';
import './app.css';

import Router from './components/router';
import { Header } from './components/sections';
import ReferenceOverlay from './components/reference-overlay';
import ConfigService from "./services/ConfigService";
import ReactGA from 'react-ga';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      config: {}
    };

    this.configService = new ConfigService();
    this.wrapper = null;
  }

  componentDidMount() {
    this.configService.load().then((x)=>{
      this.setState({ loading: false });
    }).catch(err => {
      this.setState({ loading: false, error: true });
    });
  }

  fadeIn() {
    setTimeout(() => {
      this.wrapper.className = 'hcv-app';
    })
  }

  render() {
    ReactGA.initialize('UA-135210913-1');
    if(!this.state.loading) this.fadeIn();
    return(
      <div className="hcv-app opacity" ref={r => this.wrapper = r}>
        {this.renderContent()}
      </div>
    );
  }

  renderContent() {
    if(this.state.loading || this.state.error) {
      return(
        <div className="hcv-app-loading">
          {this.renderLoaderError()}
        </div>
      );
    } else {
      return (
        <div>
          <Header />
          <Router toggleReferences={this.toggleOverlay}/>

          <ReferenceOverlay/>
        </div>
      );
    }
  }

  renderLoaderError() {
    if(this.state.loading) {
      return(<div className="loader">Loading...</div>);
    } else if(this.state.error) {
      ReactGA.pageview('/server-error/');
      return(<div className="error">Error connecting to server!</div>);
    } else {
      ReactGA.pageview('/unknown-error/');
      return(<div className="error">Something went wrong!</div>);
    }
  }
}

export default App;
