import React from 'react';

const Link = ({url}) => {
    return(
      <div className="link">
        <p>Search for my AHPRA number on the</p>
        <a href={url}>
          AHPRA Register of Practitioners
        </a>
      </div>
    );
  };
  
  export { Link };
  