import React, { Component } from 'react';
import { Tile } from '../elements';
import { Button } from '../elements';
import withExternalLinkListener from "../link-listener";

class IconTile extends Component {
  render() {
    const { line_1, line_2, number, unit, line_3, line_4 } = this.props;
    return(
      <Tile>
        <div className="tile-title">{line_1}</div>
        <div className="tile-title">{line_2}</div>
        <div className="tile-icon-weeks">
          <div className="icon-number">{number}</div>
          <span>{unit}</span>
        </div>
        <div className="tile-sub-title">{line_3}</div>
        <div className="tile-sub-title">{line_4}</div>
      </Tile>
    );
  }
}

export default withExternalLinkListener(IconTile);
