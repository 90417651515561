export function toggleReferences(open) {
  return {
    type: 'TOGGLE_OVERLAY',
    payload: open
  }
}
export function toggleMenu(open) {
  return {
    type: 'TOGGLE_MENU',
    payload: open
  }
}

export function loginUser() {
  return {
    type: 'AUTH_USER',
  }
}

export function logoutUser(open) {
  return {
    type: 'UNAUTH_USER',
  }
}