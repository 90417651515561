import {toast} from "react-toastify";
import axios from "axios";
import * as React from "react";

class BaseService {
    getHeaders() {
        return {
            headers: {
                'Content-type': 'application/json',
            }
        };
    }

    handleError(rejectHandler,error,defaultMessage){
        let response = error.response;

        if(!response || !response.data){
            response = {
                data: {
                    status:"Failure",
                    messages:[],
                    isSuccessful:false
                }
            };
        }
        if(response.data.messages && response.data.messages.length > 0) {
            toast.error(React.createElement("div",null,
                response.data.messages.map((message)=>{
                    return React.createElement("div",null,message)
                })));
        }
        else{
            toast.error(defaultMessage);
        }

        rejectHandler(response.data);
    }

    put(url,data,extraOptions){
        const options = Object.assign({},this.getHeaders(),extraOptions);

        return new Promise((resolve,reject)=> {
            axios.put(url, data, options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    this.handleError(reject,error,"Failed to update.");
                });
        });
    }

    post(url,data,extraOptions){
        const options = Object.assign({},this.getHeaders(),extraOptions);

        return new Promise((resolve,reject)=> {
            axios.post(url, data, options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    this.handleError(reject,error,"Failed to create.");
                });
        });
    }

    get(url,extraOptions){
        const options = Object.assign({},this.getHeaders(),extraOptions);

        return new Promise((resolve,reject)=> {
            axios.get(url, options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    this.handleError(reject,error,"Failed to get.");
                });
        });
    }

    delete(url,extraOptions){
        const options = Object.assign({},this.getHeaders(),extraOptions);

        return new Promise((resolve,reject)=> {
            axios.delete(url, options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    this.handleError(reject,error,"Failed to delete.");
                });
        });
    }
}

export default BaseService;