import React, { Component } from 'react';
import { FormFooter, Overlay } from '../../sections';
import { Button, RichText } from '../../elements';
import * as actions from '../../../actions';
import { connect } from 'react-redux';
import {Calculator} from "../../elements/calculator";
import ReactGA from 'react-ga';
class StepThree extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nextDisabled: true,
      backDisabled: false,
      overlay: false,
      overlay_calculator: false,
      overlay_fibro: false,
      overlay_hepascore: false,
      selected: null
    };

    this.toggleOverlay = this.toggleOverlay.bind(this);
    this.toggleOverlayCalculator = this.toggleOverlayCalculator.bind(this);
    this.toggleOverlayFibro = this.toggleOverlayFibro.bind(this);
    this.toggleOverlayHepascore = this.toggleOverlayHepascore.bind(this);
    this.onNext = this.onNext.bind(this);
    ReactGA.pageview('/step-3/');
  }

  componentDidMount() {
    
  }

  toggleOverlay(open) {
    this.setState({ overlay: open });
  }
  
  toggleOverlayCalculator(open) {
    this.setState({ overlay_calculator: open });
    if (open) {
        ReactGA.event({
        category: 'User',
        action: 'Open Calculator'
      });
    }
  }

  toggleOverlayFibro(open) {
    this.setState({ overlay_fibro: open });
    if (open) {
        ReactGA.event({
        category: 'User',
        action: 'Open Fibroscan '
      });
    }
  }

  toggleOverlayHepascore(open) {
    this.setState({ overlay_hepascore: open });
    if (open) {
        ReactGA.event({
        category: 'User',
        action: 'Open Hepascore '
      });
    }
  }

  onNext() {
    const { mainHistory, history } = this.props;
    if(this.state.selected) {
      if(this.state.selected === 'Yes') {
        history.push('/4');
      } else {
        mainHistory.push('/result');
      }
    }
  }

  render() {
    const { config, popups } = this.props;
    const { more_information_cirrhosis: popup_info, apri_calculator: popup_calc, use_fibroscan: popup_fibro,  use_hepascore: popup_hepascore } = popups;
    const { mainHistory, history } = this.props;
    return(
      <div className="step">
        <div className="container">
          <div className="step-body">
            <div className="step-title" dangerouslySetInnerHTML={{__html:config.title}}>
            </div>
            <div className="step-main">
              <div className="step-content">
                <Button text={config.button_1} onClick={() => this.setState({ nextDisabled: false, selected: 'Yes' })} active={this.state.selected === 'Yes'} />
                <Button text={config.button_2} onClick={() => this.setState({ nextDisabled: false, selected: 'No' })} active={this.state.selected === 'No'} />
                <Button text={config.more_information} alternate onClick={() => this.toggleOverlay(true)} />
              </div>
              <div className="step-content">
                <div className="step-content-text" dangerouslySetInnerHTML={{__html:config.content}}></div>
                <div className="step-content-buttons">
                  <div className="row">
                    <div className="col-xs-6 col-sm-4">
                      <Button text={config.button_3} color="grey" onClick={() => this.toggleOverlayCalculator(true)} />
                    </div>
                    <div className="col-xs-6 col-sm-4">
                      <Button text={config.button_4} color="grey" onClick={() => this.toggleOverlayFibro(true)} />
                    </div>
                    <div className="col-xs-6 col-sm-4 button-hepascore">
                      <Button text={config.button_5} color="grey" onClick={() => this.toggleOverlayHepascore(true)} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="step-caption">
                <div className="reference-link" onClick={() => this.props.toggleReferences(true)} dangerouslySetInnerHTML={{__html:config.references_and_abbreviations}}></div>
              </div>
            </div>
          </div>
        </div>

        <FormFooter
          nextDisabled={this.state.nextDisabled}
          backDisabled={this.state.backDisabled}
          nextClick={() => this.onNext()}
          backClick={() => history.goBack()}
        />

        <Overlay open={this.state.overlay} toggleOverlay={this.toggleOverlay}>
          <div className="overlay-body">
            <div className="overlay-title" dangerouslySetInnerHTML={{__html:popup_info.title}}>
            </div>
            <div className="overlay-section">
              <RichText>
                <div dangerouslySetInnerHTML={{__html:popup_info.content}}></div>
              </RichText>
            </div>
          </div>
        </Overlay>

        <Overlay open={this.state.overlay_calculator} toggleOverlay={this.toggleOverlayCalculator}>
          <div className="overlay-body">
            <div className="overlay-title" dangerouslySetInnerHTML={{__html:popup_calc.title}}>
            </div>
            <div className="overlay-section">
              <RichText>
                <div dangerouslySetInnerHTML={{__html:popup_calc.content}}></div>
              </RichText>
              <Calculator/>
            </div>
          </div>
        </Overlay>

        <Overlay open={this.state.overlay_fibro} toggleOverlay={this.toggleOverlayFibro}>
          <div className="overlay-body">
            <div className="overlay-title" dangerouslySetInnerHTML={{__html:popup_fibro.title}}>
            </div>
            <div className="overlay-section">
              <RichText>
                <div dangerouslySetInnerHTML={{__html:popup_fibro.content }}></div>
              </RichText>
            </div>
          </div>
        </Overlay>

        <Overlay open={this.state.overlay_hepascore} toggleOverlay={this.toggleOverlayHepascore}>
          <div className="overlay-body">
            <div className="overlay-title" dangerouslySetInnerHTML={{__html:popup_hepascore.title}}>
            </div>
            <div className="overlay-section">
              <RichText>
                <div dangerouslySetInnerHTML={{__html:popup_hepascore.content}}></div>
              </RichText>
            </div>
          </div>
        </Overlay>
      </div>
    );
  }
}

export default connect(null, actions)(StepThree);
