import React from 'react';

const Button = ({ text, onClick, alternate = false, color, active, disabled, style}) => {
  let classSet = ['btn'];
  let btnColor = 'blue';
  if(alternate) {
    classSet.push('alternate');
    btnColor = 'green';
  }
  if(color === 'blue' || color === 'green' || color === 'grey') {
    btnColor = color;
  }
  if(active) {
    classSet.push('active');
  }
  classSet.push(btnColor);
  return(
    <button className={classSet.join(' ')} onClick={onClick} disabled={disabled} style={style}>
      <div className="btn-text">{text}</div>
    </button>
  );
};

export { Button };
