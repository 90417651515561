import React, { Component } from 'react';
import ConfigService from "../../services/ConfigService";
import {Footer} from "./index";
import withExternalLinkListener from "../link-listener";

class FormFooter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      config: {}
    };

    this.configService = new ConfigService();
  }

  componentDidMount() {
    this.configService.get().then((config)=>{
      this.setState ({
        config: config.wizard
      });
    });
  }

  render() {
    return(
      <div className="form-footer">
        {this.renderContent()}
      </div>
    );
  }

  renderContent() {
    const { children, backClick, backDisabled, nextClick, nextDisabled } = this.props;
    if(children) {
      return this.props.children;
    } else {
      let backClassSet = ['form-footer-btn', 'left'];
      if(backDisabled) backClassSet.push('disabled');
      let nextClassSet = ['form-footer-btn', 'right'];
      if(nextDisabled) nextClassSet.push('disabled');
      return(
        <div>
          <div className="container">
            <div className="row">
              <div className="col-6">
                <div className={backClassSet.join(' ')} onClick={backClick}>{this.state.config.back_button}</div>
              </div>
              <div className="col-6">
                <div className={nextClassSet.join(' ')} onClick={nextClick}>{this.state.config.next_button}</div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      );
    }
  }
}

export default withExternalLinkListener(FormFooter);
