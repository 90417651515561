import React, { Component } from 'react';
import { FormFooter, Overlay } from '../../sections';
import { Button, RichText } from '../../elements';
import * as actions from '../../../actions';
import { connect } from 'react-redux';
import withExternalLinkListener from "../../link-listener";
import ReactGA from 'react-ga';

class StepTwo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nextDisabled: true,
      backDisabled: false,
      overlay: false,
      selected: null
    };

    this.toggleOverlay = this.toggleOverlay.bind(this);
    this.onNext = this.onNext.bind(this);
    ReactGA.pageview('/step-2/');
  }

  componentDidMount() {
    
  }

  toggleOverlay(open) {
    this.setState({ overlay: open });
  }

  onNext() {
    const { mainHistory, history } = this.props;
    if(this.state.selected) {
      if(this.state.selected === 'No') {
        history.push('/3');
      } else {
        mainHistory.push('/result');
      }
    }
  }

  render() {
    const { config, popups } = this.props;
    const { more_information_hbvhiv: popup } = popups;
    const { mainHistory, history } = this.props;
    return(
      
      <div className="step">
        <div className="container">
          <div className="step-body">
            <div className="step-title" dangerouslySetInnerHTML={{__html:config.title}}>
            </div>
            <div className="step-main">
              <div className="step-content">
                <Button text={config.button_1} onClick={() => this.setState({ nextDisabled: false, selected: 'Yes' })} active={this.state.selected === 'Yes'} />
                <Button text={config.button_2} onClick={() => this.setState({ nextDisabled: false, selected: 'No' })} active={this.state.selected === 'No'} />
                <Button text={config.more_information} alternate onClick={() => this.toggleOverlay(true)} />
              </div>
              <div className="step-caption">
                <div className="reference-link" onClick={() => this.props.toggleReferences(true)} dangerouslySetInnerHTML={{__html:config.references_and_abbreviations}}></div>
              </div>
            </div>
          </div>
        </div>

        <FormFooter
          nextDisabled={this.state.nextDisabled}
          backDisabled={this.state.backDisabled}
          nextClick={() => this.onNext()}
          backClick={() => history.goBack()}
        />

        <Overlay open={this.state.overlay} toggleOverlay={this.toggleOverlay}>
          <div className="overlay-body">
            <div className="overlay-title" dangerouslySetInnerHTML={{__html:popup.title}}>
            </div>
            <div className="overlay-section">
              <RichText>
                <div dangerouslySetInnerHTML={{__html:popup.content}}></div>
              </RichText>
            </div>
          </div>
        </Overlay>
      </div>
    );
  }
}

export default connect(null, actions)(withExternalLinkListener(StepTwo));
