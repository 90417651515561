import React, { Component } from 'react';
import { Overlay, Footer } from '../sections';
import { RichText } from '../elements';
import { connect } from 'react-redux';
import ConfigService from '../../services/ConfigService';
import withExternalLinkListener from "../link-listener";
import * as actions from "../../actions";
import ReactGA from 'react-ga';

class About extends Component {
  constructor(props) {
    super(props);

    this.state = {
      config:{},
      overlay: false,
      overlay_config: {},
      home_config: {}
    };

    this.toggleOverlay = this.toggleOverlay.bind(this);

    this.configService = new ConfigService();

    this.configService.get().then((config)=>{
      this.setState ({
        config: config.about_page,
        overlay_config: config.popups.more_information_home,
        home_config: config.home_page
      });
    });
    ReactGA.pageview('/about/');
  }

  toggleOverlay(open) {
    this.setState({ overlay: open });
  }

  render() {
    return(
      <div className='about-wrapper'>
        <div className="container">
          <div className="about">
            <div className="about-title" dangerouslySetInnerHTML={{__html:this.state.config.title}}>
            </div>
            <div className="about-text" dangerouslySetInnerHTML={{__html:this.state.config.content}}>
            </div>
            <div className="start-caption">
              <div className="reference-link" onClick={() => this.props.toggleReferences(true)} dangerouslySetInnerHTML={{__html:this.state.home_config.references_and_abbreviations}}></div>
            </div>
          </div>
        </div>

        <Footer/>
        <Overlay open={this.state.overlay} toggleOverlay={this.toggleOverlay}>
          <div className="overlay-body">
            <div className="overlay-title" dangerouslySetInnerHTML={{__html:this.state.overlay_config.title}}>
            </div>
            <div className="overlay-section">
              <RichText>
                <div dangerouslySetInnerHTML={{__html:this.state.overlay_config.content}}></div>
              </RichText>
            </div>
          </div>
        </Overlay>
      </div>
    );
  }
}

export default connect(null, actions)(withExternalLinkListener(About));
