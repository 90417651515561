let defaultState = {
  menu: false,
  overlay: false,
  authenticated: false
};

const reducers = (state = defaultState, action) => {
  switch(action.type) {
    case 'TOGGLE_OVERLAY' :
      return { ...state, overlay: action.payload };
    case 'TOGGLE_MENU' :
      return { ...state, menu: action.payload };
    case 'AUTH_USER' :
      return { ...state, authenticated: true };
    case 'UNAUTH_USER' :
      return { ...state, authenticated: false };
    default :
      return state;
  }
};

export default reducers;