import React, { Component } from 'react';
import {ProgressBar} from '../sections';
import { MemoryRouter, Route, Switch } from 'react-router-dom';
import { StepOne, StepTwo, StepThree, StepFour, StepFive, StepSix } from './steps';
import RouteWithProps from '../route-with-props';
import { connect } from 'react-redux';
import ConfigService from "../../services/ConfigService";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import withExternalLinkListener from "../link-listener";

class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      config: {},
      popups: {},
      step_6: {}
    };

    this.formBrowser = null;
    this.configService = new ConfigService();
  }

  componentDidMount() {
    if(this.formBrowser) {
      setTimeout(() => this.formBrowser.history.push('/1'));
    }
    this.configService.get().then((config)=>{
      let step_6 = {
        result_1: config.wizard.result_1,
        result_2: config.wizard.result_2,
        result_3: config.wizard.result_3,
        popups: { ...config.popups }
      };
      this.setState ({
        config: config.wizard,
        popups: config.popups,
        step_6
      });
    });
  }

  render() {
    return(
      <div className='form'>
        <MemoryRouter>
          <Route render={props => {
            this.formBrowser = props;
            let step = parseInt(props.location.pathname.slice(1));
            return(
              <div>
                <div className="container">
                  <div className="form-header">
                    <ProgressBar steps={6} currentStep={step} />
                  </div>
                </div>
                <Route render={({location}) => (
                    <div>
                      <Switch location={location}>
                        <Route path="/1" exact component={RouteWithProps(StepOne, { mainHistory: this.props.history, config: this.state.config.step_1, popups: this.state.popups })} />
                        <Route path="/2" exact component={RouteWithProps(StepTwo, { mainHistory: this.props.history, config: this.state.config.step_2, popups: this.state.popups })} />
                        <Route path="/3" exact component={RouteWithProps(StepThree, { mainHistory: this.props.history, config: this.state.config.step_3, popups: this.state.popups })} />
                        <Route path="/4" exact component={RouteWithProps(StepFour, { mainHistory: this.props.history, config: this.state.config.step_4, popups: this.state.popups })} />
                        <Route path="/5" exact component={RouteWithProps(StepFive, { config: this.state.config.step_5, popups: this.state.popups })} />
                        <Route path="/6" exact component={RouteWithProps(StepSix, { mainHistory: this.props.history, config: this.state.step_6 })} />
                      </Switch>
                    </div>
                )} />
              </div>
            );
          }}/>
        </MemoryRouter>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { menu_open: state.menu }
}

export default connect(mapStateToProps)(withExternalLinkListener(Form));
