import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Redirect} from "react-router-dom";

export default function(ComposedComponent) {
    class Authentication extends Component {

        shouldComponentUpdate(nextProps, nextState, nextContext) {
            return this.props.authenticated !== nextProps.authenticated;
        }

        render() {
            if(this.props.authenticated) {
                return <ComposedComponent {...this.props} />
            }
            else{
                return <Redirect to="/login"/>;
            }
        }
    }

    function mapStateToProps(state) {
        return { authenticated: state.authenticated };
    }

    return connect(mapStateToProps)(Authentication);
}