import React from 'react';

const TabTile = ({title, content}) => {
  return(
    <div className="tab-tile">
      <div className="tab-tile-header" dangerouslySetInnerHTML={{__html:title}}>
      </div>
      <div className="tab-tile-body" dangerouslySetInnerHTML={{__html:content}}>
      </div>
    </div>
  );
};

export { TabTile };